import Logo from './components/logo/logo';
import './app.sass';

function App() {
  return (
    <div className="App">
      <div className="title">
        {/*<span className="text">Jazzy</span>*/}
        {/*<span className="char">.</span>*/}
        {/*<span className="text">Customz</span>*/}
        {/*<span className="char">()</span>*/}
        <Logo />
      </div>
      <div className="dev-mode">
        Under Construction
      </div>
      {new Array(240).fill(0).map(fly => (<div className="firefly"></div>))}
    </div>
  );
}

export default App;
